@import '../../../styles/customMediaQueries.css';

.root {
  display: none;

  /* Borders */
  outline: none;
}

.popup {
  /* By default hide the content */
  opacity: 0;
  display: block;
  visibility: hidden;
  pointer-events: none;

  /* Position */
  position: absolute;
  z-index: var(--zIndexPopup);

  /* Layout */
  margin-top: 7px;
  padding: 15px 30px 20px 30px;
  background-color: var(--colorWhite);

  /* Borders */
  box-shadow: var(--boxShadowPopup);
  border-radius: var(--borderRadiusMedium);
  transition: var(--transitionStyleButton);
  border-top: 1px solid var(--colorGrey100);
}

.isOpenAsPopup {
  opacity: 1;
  display: block;
  visibility: visible;
  pointer-events: auto;
}

.plain {
  width: 100%;

  @media (--viewportMedium) {
    padding-top: 8px;
    padding-bottom: 16px;
  }
}

.isOpen {
  display: block;
}

.contentWrapper {
  margin-top: 0px;
  max-width: 300px;
  padding-top: 5px;
  padding-bottom: 5px;

  display: flex;
  flex-wrap: wrap;

  @media (max-width: 767px) {
    width: 100%;
    max-width: unset;
  }
}

@media (--viewportMedium) {
  .contentWrapper {
    margin-top: 0px;
    padding-top: 0px;
    padding-bottom: 8px;
  }
}

.label {
  composes: h5 from global;
  margin: 0 18px 0 0;
  padding: 11px 0 13px 0;
  font-weight: var(--fontWeightSemiBold);
}

@media (--viewportMedium) {
  .label {
    padding: 8px 0 16px 0;
  }
}

.inputsWrapper {
  width: 100%;
  display: flex;

  @media (--viewportMedium) {
    padding-bottom: 5px;
  }
}

.minPrice,
.maxPrice {
  /* width: 48px; */
  width: 100%;
  text-align: center;
  -moz-appearance: textfield;

  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    margin: 0;
    -webkit-appearance: none;
  }

  @media (--viewportMedium) {
    padding: 3px 0 3px 0;
  }
}

.priceInSidebar {
  /* width: 72px; */
}

.priceSeparator {
  margin: 6px 8px 0 8px;

  @media (--viewportMedium) {
    margin: 5px 8px 0 8px;
  }
}

.sliderWrapper {
  display: flex;
  padding: 17px 0 25px 0;
}

@media (--viewportMedium) {
  .sliderWrapper {
    padding: 3px 0 24px 0;
  }
}

.buttonsWrapper {
  display: flex;
  justify-content: flex-end;
}

.clearButton,
.cancelButton,
.submitButton {
  composes: textSmall from global;
  font-weight: var(--fontWeightMedium);

  /* Layout */
  padding: 0;

  /* Override button styles */
  border: none;
  outline: none;
  cursor: pointer;

  &:focus,
  &:hover {
    transition: width var(--transitionStyleButton);
  }
}

.clearButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0 auto 0 0;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.cancelButton {
  color: var(--colorGrey300);

  /* Layout */
  margin: 0;

  /* clearButton will add all available space between cancelButton,
   * but some hard coded margin is still needed
   */
  margin-left: 48px;

  &:focus,
  &:hover {
    color: var(--colorGrey700);
  }
}

.submitButton {
  /* Layout */
  margin: 0;
  /* margin: 0 0 0 19px; */

  color: var(--marketplaceColor);

  &:focus,
  &:hover {
    color: var(--marketplaceColorDark);
  }
}

.singleSubmitButton {
  margin: 0;
  padding: 0;
  padding: 6px;
  min-height: auto;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  border-radius: 8px;
  font-family: var(--fontFamilyRoboto);

  @media (--vwMaxM) {
    margin-top: 10px;
  }
}

.priceRangeTextDiv {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.minPriceDiv,
.maxPriceDiv {
  width: 28px;

  & input {
    margin: 0;
    padding: 0;
    border: none;
    border-radius: 0%;
    /* background-color: blue; */

    &:focus {
      box-shadow: none;
    }

    &:disabled {
      cursor: default;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }

    @media (max-width: 767px) {
      font-size: 14px;
      line-height: 14px;
    }
  }
}

.maxPriceDiv {
  user-select: none;
  margin: 0 0px 0 auto;

  @media (max-width: 375px) {
    margin: 0 0px 0 50%;
  }
}

.radiusAlert {
  font-size: 13px;
  font-weight: 700;
  line-height: 30px;
  color: var(--colorFail);
}
